.szh-menu {
  width: 100vw;
  height: 450px;
  padding-inline: 48px;
  position: fixed;
  top: 7rem;
  left: 0;
  background-color: white;
}

.szh-menu-custom {
  width: 100vw;
  height: 450px;
  padding-inline: 48px;
  position: fixed;
  top: 3rem;
  left: 0;
  background-color: white;
}

.szh-menu-mobile {
  position: fixed;
  right: 0;
  top: 50px;
  height: 100vh;
  max-width: 20rem;
  background-color: white;
}

.szh-menu__item {
  padding-inline: 0px;
  background-color: white;
}

.szh-menu__item:hover {
  text-decoration: underline;
}
