.slider {
  appearance: none;
  background: transparent;
  height: 6px;
  width: 100%;
}
.slider:focus {
  outline: none;
}

.slider::-webkit-slider-thumb {
  appearance: none;

  cursor: pointer;
  /* pointer-events: none; */
  width: 28px;
  height: 28px;
  margin-top: -10px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  border: 8px solid currentColor;
}
.slider::-webkit-slider-runnable-track {
  appearance: none;
  height: 6px;
}

.slider::-moz-range-thumb {
  appearance: none;

  cursor: pointer;
  /* pointer-events: none; */
  width: 28px;
  height: 28px;
  margin-top: -10px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  border: 8px solid currentColor;
}
.slider::-moz-range-track {
  appearance: none;
  height: 6px;
}

@media (min-width: 1024px) {
  .slider::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    margin-top: -5px;
    border: 5px solid currentColor;
  }
  .slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    margin-top: -5px;
    border: 5px solid currentColor;
  }
}
