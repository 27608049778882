/* Used this syntax to increase specificity, otherwise styles were not applied */
.smd-drop-menu.smd-drop-menu {
  border-radius: 0;
  padding: 0;
}
.smd-drop-menu .smd-drop-menu-item {
  padding: 0.625rem;
}
.smd-drop-menu .smd-drop-menu-item:active {
  background-color: var(--smd-primary);
  color: white;
}
.smd-drop-menu .smd-drop-menu-item:active * {
  color: white;
}
