.container {
  position: relative;
}

.customContainer {
  position: static;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  display: block;
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  max-height: 12rem;
  overflow-y: auto;
  border: 1px solid #e4e4e4;
  border-radius: 0.25rem;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 2;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.customSuggestionsList {
  position: absolute;
  margin: 0;
  padding: 0px 15px;
  list-style-type: none;
  z-index: 10;
  background-color: white;
  max-width: fit-content;
  min-width: 340px;
  max-height: 18rem;
  overflow-y: auto;
  border: 1px solid #e4e4e4;
  border-radius: 0.25rem;
  margin-right: 1rem;
}

.suggestion {
  font-size: 0.875rem;
  color: #2a2a2a;
  max-width: 28rem;
}
