.loader {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.loader div {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 0.25rem solid var(--smd-primary);
  border-radius: 50%;
  border-color: var(--smd-primary) transparent transparent transparent;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
