.toc ul {
  padding-left: 1.5rem;
}
.toc li {
  position: relative;
  margin: 0.5rem 0;
}

/* tree lines */
.toc li:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -0.75rem;
  width: 0.75rem;
  height: 1.25rem;
  border-left: 1px dashed lightgray;
  border-bottom: 1px dashed lightgray;
}
.toc li:first-child:before {
  top: -0.5rem;
  height: 1.75rem;
}

.toc li:after {
  content: '';
  display: block;
  position: absolute;
  left: -0.75rem;
  bottom: -0.5rem;
  height: calc(100% - 0.75rem);
  border-left: 1px dashed lightgray;
}
.toc li:last-child:after {
  display: none;
}

/* first level items */
.toc > ul {
  padding-left: 0;
}
.toc > ul > li:first-child {
  margin-top: 0;
}
.toc > ul > li:last-child {
  margin-bottom: 0;
}
.toc > ul > li:before {
  display: none;
}
.toc > ul > li:after {
  display: none;
}

/* expand animation */
/*
.open ul {
  transform: scaleY(0);
  transform-origin: top center;
  animation: expand .2s forwards;
}
@keyframes expand {
  100% {
    transform: scaleY(1) translateY(0);
  }
}
*/

/* top level line */
/*
.toc {
  margin-left: 1.5rem;
}
.toc > ul > li:before,
.toc > ul > li:first-child:before {
  content: '';
  display: block;
  position: absolute;
  top: .75rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  background: white;
  border: 2px solid #38b2ac;
  border-radius: 50%;
  z-index: 1;
}
.toc > ul > li:after {
  content: '';
  display: block;
  position: absolute;
  top: 1.75rem;
  left: -1.025rem;
  height: calc(100% - .5rem);
  border: none;
  border-right: 1px dashed #38b2ac;
}
*/
