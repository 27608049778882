@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --smd-primary: var(--palette-primary, #0c6972);
  --smd-primary-dark: var(--palette-primaryDark, #00545b);
  --smd-secondary: var(--palette-secondary, #dffafb);
  --smd-secondary-dark: var(--palette-secondaryDark, #b2e6e9);
  --smd-secondary-light: var(--palette-secondaryLight, #f9fdfd);
  --smd-tertiary: var(--palette-tertiary, #1aa9b4);
  --smd-tertiary-light: var(--palette-tertiaryLight, #effeff);
  --smd-topbar: var(--palette-topbar, var(--smd-primary));
  --smd-tooltip-bg: var(--pallete-tooltipBg, #d1eef0);
  --smd-card-bg: var(--palleete-cardBg, #f1f8f8);
}

@layer components {
  .storymd-plus {
    --size: calc(var(--icon-size, 0.75) * 1em);

    all: unset;
    width: var(--size);
    height: var(--size);

    /* %23 - is the encoded # symbol */
    /* Using --smd-tertiary color - %231aa9b4 */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cg%3E%3Cpath fill='none' d='M0 0h12v12H0z'/%3E%3Cg%3E%3Cg fill='%231aa9b4'%3E%3Cpath d='M4.73.656h2.531v10.688H4.73z'/%3E%3Cpath d='M11.312 4.727v2.545H.681V4.727z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

    @apply inline-block bg-transparent bg-contain bg-center bg-no-repeat;
  }

  .debug {
    @apply border-5 border-pink-500;
  }
}

@layer base {
  img {
    @apply pointer-events-none select-none;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

@layer utilities {
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg-blur {
    backdrop-filter: blur(4px);
  }
  .writing-vertical {
    writing-mode: vertical-lr;
  }
  .empty:before {
    content: '\200b'; /* zero width space */
  }
  .remove-links a {
    @apply pointer-events-none text-inherit no-underline;
  }
  .smd-focus-visible-primary {
    @apply focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-smd-focus-primary focus-visible:ring-offset-2;
  }
  .smd-focus-visible-secondary {
    @apply focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-smd-focus-secondary focus-visible:ring-offset-2;
  }
  .smd-group-focus-visible-primary {
    @apply group-focus-visible:outline-none group-focus-visible:ring-4 group-focus-visible:ring-smd-focus-primary group-focus-visible:ring-offset-2;
  }
  .smd-group-focus-visible-secondary {
    @apply group-focus-visible:outline-none group-focus-visible:ring-4 group-focus-visible:ring-smd-focus-secondary group-focus-visible:ring-offset-2;
  }
  .smd-focus-within-primary {
    @apply focus-within:outline-none focus-within:ring-4 focus-within:ring-smd-focus-primary focus-within:ring-offset-2;
  }
  .smd-focus-within-secondary {
    @apply focus-within:outline-none focus-within:ring-4 focus-within:ring-smd-focus-secondary focus-within:ring-offset-2;
  }
  .smd-input-focus-primary {
    @apply focus:outline-none focus:ring-4 focus:ring-smd-accent-light focus:ring-offset-1 focus:ring-offset-smd-accent;
  }
  .smd-input-focus-secondary {
    @apply focus:outline-none focus:ring-2 focus:ring-smd-accent-bright;
  }
  .smd-input-focus-within-primary {
    @apply focus-within:ring-4 focus-within:ring-smd-accent-light focus-within:ring-offset-2 focus-within:ring-offset-smd-accent;
  }
  .smd-input-group-focus-visible-primary {
    @apply group-focus-visible:outline-none group-focus-visible:ring-4 group-focus-visible:ring-smd-accent-light group-focus-visible:ring-offset-1 group-focus-visible:ring-offset-smd-accent;
  }
  .smd-transform-duration {
    @apply hover:scale-1025 md:transform md:duration-100;
  }
  .smd-label {
    @apply text-smd-sm font-semibold text-smd-gray-darker;
  }
  .word-break {
    word-break: break-word;
  }
}

html,
body,
#root {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

::-webkit-details-marker {
  display: none;
}

.ql-editor {
  min-height: 120px;
  max-height: 120px;
  overflow-y: scroll;
  width: 100%;
  font-family: Open Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  word-break: break-word;
}

.ql-tooltip.ql-editing {
  margin-left: 125px;
}

#emoji-close-div {
  position: relative !important;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  padding: 1px 4px 1px 4px !important;
  background-color: #f7f7f7;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: #d1d1d1 !important;
  font-weight: 400 !important;
  font-size: 16px;
  font-family: Open Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.list-container ol,
.list-container ul {
  list-style-type: revert;
  margin-left: 3rem;
}

.ql-editor p a,
.anchor-container a {
  color: var(--smd-primary);
  text-decoration: underline;
  cursor: pointer;
}

.react-player__preview {
  background-repeat: no-repeat;
  background-size: contain !important;
}

.lightbox-caption a,
.asset-description a {
  color: var(--smd-tertiary);
}

.custom-list-style ul {
  list-style-type: disc;
  padding-left: 2rem;
}

.custom-list-style ol {
  list-style-type: decimal;
  padding-left: 2rem;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

.react-datepicker__triangle {
  display: none !important;
}

.index-table-of-contents > ul > li > button:not(.active),
.index-table-of-contents > ul > li > ul > li > button:not(.secondaryactive) {
  border: none !important;
}

.custom-bullet ul {
  @apply list-none space-y-4;
}

.custom-bullet ul li {
  @apply flex items-start;
}

.custom-bullet ul li::before {
  content: '';
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%230c6972' stroke-width='3'><path stroke-linecap='round' stroke-linejoin='round' d='M5 13l4 4L19 7' /></svg>");
  @apply mr-2 inline-block h-6 w-6 shrink-0 bg-no-repeat text-smd-accent;
}
