.slider {
  appearance: none;
  background: transparent;
  width: 100%;
}
.slider:focus {
  outline: none;
}

.slider::-webkit-slider-thumb {
  appearance: none;

  cursor: pointer;
  /* pointer-events: none; */
  width: 18px;
  height: 18px;
  margin-top: -5px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid currentColor;
}

.slider::-moz-range-thumb {
  appearance: none;

  cursor: pointer;
  /* pointer-events: none; */
  width: 18px;
  height: 18px;
  margin-top: -5px;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid currentColor;
}
