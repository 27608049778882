.container {
  overscroll-behavior: contain; /* use to prevent from navigating with trackpad when scrolling*/
  overflow: scroll;
  scrollbar-width: none; /* ff */
  -ms-overflow-style: none; /* ie 10+ */
}

/* webkit */
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
